import { nav, activities as $act } from 'utils/const';

export const URLS = {
    ROOT: "/",
    PAGE_MSG: '/page-msg',
    STAT_PAGE: "/panel-de-usuario",
    BOOK_PAGE: "/libro",
}

export const routes = [
    {
        path: URLS.ROOT,
        getView: async () => (await import('screens/root/login')).default,
        config: {
            nav: nav.NONE,
            hideMobileMenu: true,
            outSite: true,
            unity: false,
        }
    },
    {
        path: URLS.PAGE_MSG,
        getView: async () => (await import('screens/flash-link')).default,
        config: {
            nav: nav.NONE,
            hideMobileMenu: true,
            outSite: true,
            unity: false,
        }
    },
    {
        path: URLS.STAT_PAGE,
        getView: async () => (await import('screens/user-panel')).default,
        config: {
            nav: nav.MENU,
            act: 1,
            unity: false,
        }
    },
    {
        path: '/example-page',
        getView: async () => (await import('screens/example-page')).default,
        config: {
            nav: nav.MENU,
            outSite: true,
            unity: false,
        }
    },
    {
        path: '/status',
        getView: async () => (await import('screens/status')).default,
        config: {
            nav: nav.BACK,
            outSite: true,
            unity: false,
        }
    },
    {
        path: '/recuperar-contrasena',
        getView: async () => (await import('screens/root/forgot_pass')).default,
        config: {
            nav: nav.NONE,
            outSite: true,
            unity: false,
        }
    },
    {
        path: '/contrasena-temporal',
        getView: async () => (await import('screens/root/temporal_pass')).default,
        config: {
            nav: nav.NONE,
            outSite: true,
            unity: false,
        }
    },
    {
        path: '/crear-nueva-contrasena',
        getView: async () => (await import('screens/root/create_new_password')).default,
        config: {
            nav: nav.NONE,
            outSite: true,
            unity: false,
        }
    },
    {
        path: '/registro',
        getView: async () => (await import('screens/register')).default,
        config: {
            nav: nav.NONE,
            outSite: true,
            unity: false,
        }
    },
    {
        path: '/planning',
        getView: async () => (await import('screens/planning')).default,
        config: {
            nav: nav.MENU,
            unity: false
        }
    },
    {
        path: '/mis-tests',
        getView: async () => (await import('screens/test/index')).default,
        config: {
            nav: nav.MENU,
            act: $act.TEST,
            unity: false
        }
    },
    {
        path: '/hacer-test',
        getView: async () => (await import('screens/test/do')).default,
        config: {
            nav: nav.BACK,
            unity: false
        }
    },
    {
        path: '/resumen-resultados-test',
        getView: async () => (await import('screens/test/summary')).default,
        config: {
            nav: nav.BACK,
            unity: false
        }
    },
    {
        path: URLS.BOOK_PAGE,
        getView: async () => (await import('screens/book/index')).default,
        config: {
            nav: nav.MENU,
            act: $act.LUDU_LIBRO,
            outSite: true,
            unity: false
        }
    },
    {
        path: '/esquema',
        getView: async () => (await import('screens/book/scheme')).default,
        config: {
            nav: nav.NONE,
            act: $act.LUDU_LIBRO,
            unity: false
        }
    },
    {
        path: '/fichas-memo',
        getView: async () => (await import('screens/memory/index')).default,
        config: {
            nav: nav.MENU,
            unity: false
        }
    },
    {
        path: '/genera-fichas-memo',
        getView: async () => (await import('screens/memory/create')).default,
        config: {
            nav: nav.NONE,
            act: $act.MEMO,
            unity: false
        }
    },
    {
        path: '/hacer-fichas-memo',
        getView: async () => (await import('screens/memory/do')).default,
        config: {
            nav: nav.NONE,
            unity: false
        }
    },
    {
        path: '/mis-cursos',
        getView: async () => (await import('screens/my-courses')).default,
        config: {
            nav: nav.MENU,
            unity: false
        }
    },
    {
        path: '/mis-cursos-individual',
        getView: async () => (await import('screens/my-courses-individual')).default,
        config: {
            nav: nav.MENU,
            unity: false
        }
    },
    {
        path: '/invitar-amigos',
        getView: async () => (await import('screens/invite-friends')).default,
        config: {
            nav: nav.MENU,
            unity: false
        }
    },
    {
        path: '/crear-test',
        getView: async () => (await import('screens/test/create')).default,
        config: {
            nav: nav.BACK,
            act: $act.TEST,
            unity: false
        }
    },
    {
        path: '/logros',
        getView: async () => (await import('screens/achievements')).default,
        config: {
            nav: nav.MENU,
            act: $act.TEST,
            unity: false
        }
    },
    {
        path: '/rankings',
        getView: async () => (await import('screens/rankings')).default,
        config: {
            nav: nav.MENU,
            act: $act.TEST,
            unity: true
        }
    },
    {
        path: '/opozulo',
        getView: async () => (await import('screens/u_opozulo')).default,
        config: {
            nav: nav.MENU,
            act: $act.TEST,
            unity: true
        }
    },
    {
        path: '/alumnis',
        getView: async () => (await import('screens/u_luducompis')).default,
        config: {
            nav: nav.MENU,
            act: $act.TEST,
            unity: true
        }
    },
    {
        path: '/pomodoro',
        getView: async () => (await import('screens/u_pomodoro')).default,
        config: {
            nav: nav.MENU,
            act: $act.TEST,
            unity: true
        }
    },
    {
        path: '/deconstructor',
        getView: async () => (await import('screens/u_deconstructor')).default,
        config: {
            nav: nav.MENU,
            act: $act.DECONSTRUCTOR,
            unity: true
        }
    },
];

export const notFound = {
    path: '/no-se-encontro-la-pagina',
    getView: async () => (await import('screens/not_found')).default,
    config: {
        nav: nav.NONE,
        hideMobileMenu: true,
        outSite: true,
        unity: false
    }
}

