import { render, html } from 'lit-html';
import styles from './_.styles.scss';
import { toCamelCase } from 'utils/to-camel-case';

class BackMenu extends HTMLElement {
  static get observedAttributes() {
    return [
      'back-link-label',
      'back-link-url',
    ];
  }

  get backLinkLabel() {return this.__backLinkLabel}
  set backLinkLabel(value) {
    this.__backLinkLabel = value;
    this.#__render();
  }

  get backLinkUrl() {return this.__backLinkUrl}
  set backLinkUrl(value) {
    this.__backLinkUrl = value;
    this.#__render();
  }

  /** Initializer of component definition */
  constructor() {
    super();
    this.#__init();
  }

  /** Trigger change props provided to the component */
  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (oldValue !== newValue) {
      this[toCamelCase(attributeName)] = newValue
      this.#__render();
    }
  }

  /** Element created and initialized in DOM */
  connectedCallback() {
    this.__initialized = true;
    this.#__render();
  }

  disconnectedCallback() { }

  /** ______________________ RENDER ______________________________ */
  #template() {
    const {
      backLinkUrl,
      backLinkLabel,
    } = this;

    return /* template */html`
      <div class="flex items-center h-28 pr-16">
        <a
          class="title_6 medium no-underline ml-5 flex items-center ml_dark"
          href="${backLinkUrl}">
          <i class="icon-1 rotate-180 ml-icon-flecha ml_primary flex justify-center items-center">
          </i>
          ${backLinkLabel}
        </a>
      </div>
    `;
  }
  /** ____________________________________________________________ */



  /**
   * ========================= PRIVATE FUNCTIONS ===========================
   * (Don't modify the functions below. Copy-paste to other web components)
   * ==================================================================== */

  /** Init web component config to be executed in constructor */
  #__init({ shadowDOM = true } = {}) {
    this.__isShadowDOM = shadowDOM;
    if (this.__isShadowDOM) this.root = this.attachShadow({ mode: 'open'});
    this.#__render();
    this.#__attachStyle();
    this.__initialized = false;
  }

  /** Inject processed SCSS Style */
  #__attachStyle() {
    const style = document.createElement('style');
    style.textContent = styles
      .replace(/(\/)([\w?\d?])/g, '\\/$2')              // Fix for tailwind 'w-1/2' classes
      .replace(/(\[)(\w?\d?(?:rem|px|%)?)/g, '\\[$2\\') // Fix for tailwind custom 'w-[50px]' classes
      .replace(/(\.\w+)(\:)(\w+)/g, '$1\\:$3');
    const appendStyleTo = this.__isShadowDOM
      ? this.root
      : document.head || document.getElementsByTagName('head')[0];

    appendStyleTo.prepend(style);
  }


  /** Internal function to update the UI based (re-render and first render) */
  #__render() {
    const attachTo = this.__isShadowDOM ? this.root : this;
    render(this.#template(), attachTo);
  }
  /** ============================================================= */
}

window.customElements.define('ml-back-menu', BackMenu);

