import { item_types as $it, treeItem as $ } from 'utils/const';
import { activities as $act } from 'utils/const';
import { EnrollStorage } from 'storage/Enroll.storage';

export const intToStr = (nro) =>  {
  if(nro == 0) return '0';
  return nro.toString().padStart(2, '0');
}

export const themeColor = (color) => getComputedStyle(document.documentElement).
  getPropertyValue(color);


export const getHourAndMinutesFromSeconds = (seconds) => {
  seconds = Number(seconds);
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor(seconds % 3600 / 60);
  return { hours, minutes }; 
}

export const addCls = (element, classes) => {
  let clsArray = classes.trim().split(' ');
  clsArray.forEach(cls => element.classList.add(cls));
}

export const findNodeByField = (value, node, field, path=[]) => {
  if (node[field] === value) return node;
  path.push({ ...node });
  for (let i = 0, child; child = node.h[i]; ++i) {
    let node_found = findNodeByField(value, child, field, path);
    if (node_found) return node_found;
  }
  path.pop();
  return null
};

export const capitalizeWord = (word) => {
  let lowerCase = word.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
}

export const getTitleForBookPage = (nodeLoaded, arrayTitle) => {
  if(!Boolean(nodeLoaded)) return '--';
  let firstWord = capitalizeWord(arrayTitle[0]);
  if(arrayTitle.length == 2) return `${firstWord} ${arrayTitle[1]}.`;
  if(arrayTitle.length == 3) return `${firstWord} ${arrayTitle[1]}. ${arrayTitle[2]}`;
  return firstWord;
}

export const getTitleForLawPages = (nodeLoaded, node) => {
  if(!Boolean(nodeLoaded)) return '--';
  let arrayTitle = node[$.cnt].split(' ');
  let splitRange = 2;
  if(arrayTitle[2] && arrayTitle[2].trim().toLowerCase() == "bis") splitRange = 3;
  return getTitleForBookPage(nodeLoaded, arrayTitle.splice(0, splitRange));
}

export const getSubTitleForLawPages = (nodeLoaded, node) => {
  if(!Boolean(nodeLoaded)) return '--';
  let arrayTitle = node[$.cnt].split(' ');
  let splitRange = 2;
  if(arrayTitle[2] && arrayTitle[2].trim().toLowerCase() == "bis") splitRange = 3;
  return arrayTitle.splice(splitRange).join(" ");
}

export const calcPercentage = (value, total) => {
  if(total == 0) return '0';
  return intToStr(Math.round((value * 100) / total))
};

export const equalArrayObj = (array_1, array_2, key = 'id') => {
  if(array_1.length != array_2.length) return false;
  let differences = array_1.filter(obj1 => {
    if(!array_2.some(obj2 => obj2[key] == obj1[key])) 
      return obj1;
  })
  return differences.length == 0;
}

export const checkAndReverse = (path, node) => {
  if(node[$.typ] == $it.PARAGRAPH) return "";
  if(!Boolean(path) || path.length == 0) return [];
  if(path[0].id == node[$.id]) return path.reverse();
  return path;
}

export const getAllArticles = (subTree) => {
  let articles = [];
  _getAllArticles(subTree, articles);
  return articles;
}

const _getAllArticles = (subTree, articles) => {
  if (subTree[$.typ] === $it.ARTICLE) articles.push(subTree);

  subTree[$.children].forEach(node => _getAllArticles(node, articles));
};

export const findFirstInArrayByField = (array, node, field) => {
  if (array.includes(node[field])) return node;
  for (let i = 0, child; child = node.h[i]; ++i) {
    let node_found = findFirstInArrayByField(array, child, field);
    if (node_found) return node_found;
  }
  return null
}

export const checkActivities = () => {
  const { activities } = EnrollStorage.getEnrollSelected();

  if (activities.length == 0) return false;

  return {
    hasTestAct: activities.some(act => act.type == $act.TEST),
    hasPrCaseAct: activities.some(act => act.type == $act.PRACTICAL_CASE),
    hasMemoAct: activities.some(act => act.type == $act.MEMO),
    hasBookAct: activities.some(act => act.type == $act.LUDU_LIBRO),
    hasDecoAct: activities.some(act => act.type == $act.DECONSTRUCTOR),
    hasAudioAct: activities.some(act => act.type == $act.AUDIO)
  }
}

export const getTimeFromDate = (date) => {
  if(date) return new Date(date + " ").getTime();
  return new Date().getTime();
}

export const timeToDays = (time) => {
  return Math.ceil(time / (1000 * 60 * 60 * 24));
}

export const getDaysFromDates = (dateFrom, dateTo) => {
  return timeToDays(getTimeFromDate(dateTo) - getTimeFromDate(dateFrom));
}

export const convertSecondsToTime = (seconds) => {
  let h = Math.floor(seconds / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let sec = seconds % 60;
  let time = "";

  if (h > 0) time += h + "h ";
  if (m > 0) time += m + "m ";
  if (sec > 0 || time === "") time += sec + "s";

  return time;
}

export const getUnityPixelRatio = () => {
  let newDevicePixelRatio = window.devicePixelRatio || 1;
  if (newDevicePixelRatio >= 2) {
      newDevicePixelRatio /= 2;
  }
  return newDevicePixelRatio
}

export const isOldDate = (date) => {
  let today = new Date();
  let dateToCompare = new Date(date + " ");
  return dateToCompare < today;
}

export const __addDefaultActivities = (activities) => {
  const defaultActivities = [
    $act.LUDU_LIBRO,
    $act.SCHEME,
    $act.STATS,
    $act.DECONSTRUCTOR,
    $act.POMODORO
  ]
  activities = activities.filter(activity => activity.atp != 0);
  // TODO: REVIEW WITH RUBEN DREM AND VF FOR DEFAULT ACTIVITY
  defaultActivities.forEach(activityId => {
    activities.push({
      atp: activityId,
      vt: "2052-07-12",
      drem: 1010,
      vf: "2022-07-12"
    })
  });

  return activities;
}

export const __formatLicenses = (licenses) => {
  const enrollments = [];

  licenses.forEach((license) => {
    license.acts = __addDefaultActivities(license.acts);

    enrollments.push({
      prnam: license.nm,
      prid: license.prid,
      enr_list: license.acts.map(activity => ({
        act: activity.atp,
        e_id: license.lid,
        prg: undefined,
        vt: activity.vt,
        drem: activity.drem,
        vf: activity.vf,
      }))
    })
  });

  return enrollments;
}

export const formatGetRewards = (data) => {
  return {
    today: {
      achievements: data.ac_t,
      likes: data.lks_t,
      ludis: data.lns_t,
      ludus: data.lus_t
    },
    week: {
      achievements: data.ac_w,
      likes: data.lks_w,
      ludis: data.lns_w,
      ludus: data.lus_w
    },
    general: {
      achievements: data.ac_g,
      likes: data.lks_g,
      ludis: data.lns_g,
      ludus: data.lus_g
    }
  }
}

export const resetUnityVars = () => {
  window.unityInstance = null;
  window.unityLoading = false;
  window.unityLoaded = false;
  window.unityModule = '';
}

export const killUnityInstance = async () => {
  try {
    if (window.unityInstance) {
      window.unityInstance.Quit();
      const canvas = document.querySelector('#unity-canvas'); // Reemplaza 'canvas_id' con el ID de tu canvas
      if (canvas) {
        canvas.parentNode.removeChild(canvas);
      }

      // Eliminar la instancia de Unity
      delete window.unityInstance;
      resetUnityVars();
    }
  } catch (error) {
    console.error('Error al matar la instancia de Unity:', error);
  }
};

export const __isLawObject = (node, level=1) => {
  return node[$.children][0][$.typ] === $it.PROGRAM_KB;
  // if(level > 3) return false;
  // if (node[$.typ] == $it.PROGRAM_KB) return true;
  // for (let i = 0, child; child = node.h[i]; ++i){
  //   if(__isLawObject(child, level+1)) return true;
  // }
  // return false;
}

export const __decimalToRoman = (number) => {
  number = +number;
  if(number == 0) return '0';
  
  const romanNumerals = [
    { value: 1000, numeral: 'M' },
    { value: 900, numeral: 'CM' },
    { value: 500, numeral: 'D' },
    { value: 400, numeral: 'CD' },
    { value: 100, numeral: 'C' },
    { value: 90, numeral: 'XC' },
    { value: 50, numeral: 'L' },
    { value: 40, numeral: 'XL' },
    { value: 10, numeral: 'X' },
    { value: 9, numeral: 'IX' },
    { value: 5, numeral: 'V' },
    { value: 4, numeral: 'IV' },
    { value: 1, numeral: 'I' },
  ];

  let result = '';

  for (let i = 0; i < romanNumerals.length; i++) {
    while (number >= romanNumerals[i].value) {
      result += romanNumerals[i].numeral;
      number -= romanNumerals[i].value;
    }
  }

  return result;
}

export const __ordinalToDecimal = (ordinal) => {
  const hasPoint = ordinal.includes('.');
  const ordinalsMap = {
    'primero': 1, 'undécimo': 11,     'vigésimo primero': 21,
    'segundo': 2, 'duodécimo': 12,    'vigésimo segundo': 22,
    'tercero': 3, 'decimotercero': 13,'vigésimo tercero': 23,
    'cuarto': 4,  'decimocuarto': 14, 'vigésimo cuarto': 24,
    'quinto': 5,  'decimoquinto': 15, 'vigésimo quinto': 25,
    'sexto': 6,   'decimosexto': 16,  'vigésimo sexto': 26,
    'séptimo': 7, 'decimoséptimo': 17,'vigésimo séptimo': 27,
    'octavo': 8,  'decimoctavo': 18,  'vigésimo octavo': 28,
    'noveno': 9,  'decimonoveno': 19, 'vigésimo noveno': 29,
    'décimo': 10, 'vigésimo': 20,     'trigésimo': 30
  };

  if(hasPoint) ordinal = ordinal.split('.')[0];
  ordinal = ordinal.toLowerCase().trim();
  if (ordinalsMap.hasOwnProperty(ordinal)) return ordinalsMap[ordinal];

  return 0;
}
