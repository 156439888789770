import { render, html } from 'lit-html';
import { toCamelCase } from 'utils/to-camel-case';
import styles from './_.styles.scss';

class MlTitle extends HTMLElement {
  /** Definition of component props to be listened when they change */
  static get observedAttributes() {
    return [
      'title',
      'sub-title',
      'fade',
      'options'
    ];
  }

  get title() { return this.getAttribute('title') };
  set title(value) {
    this.setAttribute('title', value);
  }

  get subTitle() { return this.getAttribute('sub-title') };
  set subTitle(value) {
    this.setAttribute('sub-title', value);
  }

  get fade() { return this.getAttribute('fade') };
  set fade(value) {
    this.setAttribute('fade', value == "true");
  }

  get options() { return this.getAttribute('options') };
  set options(value) {
    this.setAttribute('options', value == "true");
  }

  /** Initializer of component definition */
  constructor() {
    super();
    this.#__init();
  }

  /** Element created and initialized in DOM */
  connectedCallback() {
    this.__initialized = true;
    this.title ??= "Titulo principal";
    this.fade ??= false;
    this.options ??=false;
    this.#__render();
  }

  disconnectedCallback() { }

  /** Trigger change props provided to the component */
  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.__initialized) {
      if (oldValue !== newValue) {
        this[toCamelCase(attributeName)] = newValue
        this.#__render();
      }
    }
  }

  loadOptions(){
    const { subTitle, options } = this;
    if(options == "true"){
      return html`
        <div class="options-sub-title">
          <p class="title_5 regular"> ${subTitle} </p>
          <i class="icon-3 ml-icon-flecha_desplegar ml_primary"></i>
          <div class="options-container">
            <div>
              <p class="p_14 regular">Opción nro 01</p>
              <p class="p_14 regular">Opción numero 02 y tiene el texto mas largo</p>
              <p class="p_14 regular">Opción numero 03</p>
              <p class="p_14 regular">Opción numero 04</p>
              <p class="p_14 regular">Opción numero 05</p>
              <p class="p_14 regular">Opción numero 06</p>
              <p class="p_14 regular">Opción numero 07</p>
              <p class="p_14 regular">Opción numero 08</p>
              <p class="p_14 regular">Opción numero 09</p>
            </div>
          </div>
        </div>`
    }else{
      return html`<p class="title_5 regular">${subTitle}</p>`
    }
  }


  /** ______________________ RENDER ______________________________ */
  #template() {
    const { title, fade } = this;

    return /* template */html`
      <div class="ml_title_cmp ${fade == "true" ? "fade" : ""}">
        <h1 class="title_1">${title}</h1>
        ${this.loadOptions()}
      </div>
    `;
  }
  /** ____________________________________________________________ */




  /**
   * ========================= PRIVATE FUNCTIONS ===========================
   * (Don't modify the functions below. Copy-paste to other web components)
   * ==================================================================== */

  /** Init web component config to be executed in constructor */
  #__init({ shadowDOM = true } = {}) {
    this.__isShadowDOM = shadowDOM;
    if (this.__isShadowDOM) this.root = this.attachShadow({ mode: 'open'});
    this.#__render();
    this.#__attachStyle();
    this.__initialized = false;
  }

  /** Inject processed SCSS Style */
  #__attachStyle() {
    const style = document.createElement('style');
    style.textContent = styles;
    const appendStyleTo = this.__isShadowDOM
      ? this.root
      : document.head || document.getElementsByTagName('head')[0];

    appendStyleTo.appendChild(style);
  }


  /** Internal function to update the UI based (re-render and first render) */
  #__render() {
    const attachTo = this.__isShadowDOM ? this.root : this;
    render(this.#template(), attachTo);
  }
  /** ============================================================= */
}

window.customElements.define('ml-title', MlTitle);

