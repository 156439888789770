import { render, html } from 'lit-html';
import styles from './_.styles.scss';
import { toCamelCase } from 'utils/to-camel-case';
import { URL_STATIC } from 'utils/_.env.js';
import { RewardsApi } from 'api_connection';
import { CloudService, ATTR } from 'services/CloudService';
import ClientDB from 'services/ClientDB';
import { removeKeyFromState } from 'storage';
import { GA4 } from 'utils/analytics';
import { E_COMMERCE } from 'utils/_.env';
import { StorageService } from 'storage/Storage.storage';
import { AuthStorage } from 'storage/Auth.storage';
import { TreeStorage } from 'storage/Tree.storage';
import { EnrollStorage } from 'storage/Enroll.storage';

class TopMenu extends HTMLElement {

  static get observedAttributes() {
    return [
      'show-notifications',
      'show-help',
      'show-select',
      'title-page',
      'with-back-btn',
      'show-background'
    ];
  }

  get showNotifications() {return this.__showNotifications}
  set showNotifications(value) {
    this.__showNotifications = value === 'true';
    this.#__render();
  }

  get showHelp() {return this.__showHelp;}
  set showHelp(value) {
    this.__showHelp = value === 'true';
    this.#__render();
  }

  get showSelect() {return this.__showSelect;}
  set showSelect(value) {
    this.__showSelect = value === 'true';
    this.#__render();
  }

  get titlePage() { return this.getAttribute('title-page') };
  set titlePage(value) {
    this.setAttribute('title-page', value);
  }
  
  get withBackBtn() { return this.__withBackBtn };
  set withBackBtn(value) {
    this.__withBackBtn = value === 'true';
    this.#__render();
  }

  get showBackground() {
    const showBackground = this.getAttribute('show-background');
    return showBackground === 'true' || showBackground === true;
  }
  set showBackground(value) {
    this.setAttribute('show-background', value === 'true' || value === true);
  }

  /** Initializer of component definition */
  constructor() {
    super();
    this.#__init();
    this.$body = document.querySelector("body");
    this.onBoarding = false;
    this.onBoardingList = false;
    this.onBoardingBoxHelp = false;
  }


  /** Trigger change props provided to the component */
  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (oldValue !== newValue) {
      this[toCamelCase(attributeName)] = newValue
      this.#__render();
    }
  }

  /** Element created and initialized in DOM */
  connectedCallback() {
    this.__initialized = true;
    this.__stats = { ans_ok: 0, never_seen: 0, tot_q: 0 };
    this.__avatar = `${URL_STATIC}avatar/avatar-thumbnail.png`;
    this.$body.addEventListener("click", this.onClickOutside, true);
    this.root.addEventListener("click", this.onClickOutsideInside);
    this.__rewards = { achievements: 0, likes: 0, ludus: 0, ludis: 0 };
    this.__enrollments = [];
    this.__currentEnrollment = null;
    window.addEventListener("scroll", this.onScrollMovement.bind(this), true);
    this.#__render();
    document.addEventListener('clickShowMenuList', (e) => {
      this.onBoardingList = e.detail.showList;
      this.#__render();
    })
    document.addEventListener('clickOpenCardMenu', (e) => {
      this.onBoarding = e.detail.cardMenu;
      this.onBoarding ? this.openUser() : this.onClickOutside(e)
      this.#__render();
    })
    document.addEventListener('clickOpenBoxHelp', (e) => {
      this.onBoardingBoxHelp = e.detail.cardMenu;
      this.onBoardingBoxHelp ? this.showBoxHelp() : this.onClickOutside(e)
      this.#__render();
    })
  }

  disconnectedCallback() {
    this.$body.removeEventListener('click', this.onClickOutside);
    window.removeEventListener("scroll", this.onScrollMovement, true);
  }

  onScrollMovement = (e) => {
    const scrollPosition = window.scrollY;
    const scrolledCls = "scrolled";
    const isMobile = window.screen.width <= 800;

    if (scrollPosition > 50 && isMobile) {
      this.root.querySelector(".ml-menu").classList.add(scrolledCls);
    } else {
      this.root.querySelector(".ml-menu").classList.remove(scrolledCls);
    }
  }

  onClickOutside = (e) => {
    let component = this;
    let avatar = component.root.querySelector("#avatarMenuId");
    let helpBox = component.root.querySelector("#helpBox");
    let inputSelect = component.root.querySelector("#inputEnrollmentSelectId");
    this.onBoarding ? avatar.classList.add('markCard') : avatar.classList.remove('markCard');
    this.onBoardingBoxHelp ? component.root.querySelector('#ayuda')?.classList.add('markLawList') : component.root.querySelector('#ayuda')?.classList.remove('markLawList');
    if(e.target !== component && avatar.classList.contains("open") && !this.onBoarding ){
      avatar.classList.remove("open");
    }

    if (e.target !== component &&  !helpBox?.classList.contains("hidden") && !this.onBoardingBoxHelp) {
      helpBox?.classList.add("hidden");
    }

    if (inputSelect 
      && component.showSelect
      && e.target !== component
      && inputSelect.classList.contains("active") && !this.onBoarding ) {
      inputSelect.classList.remove("active");
    }
  }

  onClickOutsideInside(e){
    let component = e.target.closest('.ml-menu');
    let avatar = component.querySelector('#avatarMenuId');
    let inputSelect = component.querySelector('#inputEnrollmentSelectId');
    let helpBox = component.querySelector('#helpBox');
    let help = component.querySelector('#ayuda');

    if (
      helpBox && help &&
      !help.contains(e.target) && 
      !helpBox.classList.contains("hidden") && 
      !this.onBoardingBoxHelp
    ){
      helpBox.classList.add("hidden");
    }

    this.onBoarding ? avatar.classList.add('markCard') : avatar.classList.remove('markCard');
    if(!avatar.contains(e.target) && avatar.classList.contains('open') && !this.onBoarding) {
      avatar.classList.remove('open');
    }

    if(
      inputSelect && 
      !inputSelect.contains(e.target) && 
      inputSelect.classList.contains('active') && 
      !this.onBoarding 
    ){
      inputSelect.classList.remove('active');
    }
  }

  openUser = () => {
    const rewards = EnrollStorage.getRewards();
    const { licenseId } = EnrollStorage.getEnrollSelected();
    const customTree = TreeStorage.getCustomTree();

    let component = this;
    let avatar = component.root.querySelector("#avatarMenuId");
    avatar.classList.toggle('open');
    this.onBoarding ? avatar.classList.add('markCard') : avatar.classList.remove('markCard');
    let isOpen = avatar.classList.contains('open');

    if (customTree) {
      this.__stats.ans_ok = customTree.stat.ans_ok ?? 0;
      this.__stats.never_seen = customTree.stat.never_seen ?? 0;
      this.__stats.tot_q = customTree.stat.tot_q ?? 0;
    }

    this.__rewards = rewards.general;
    if(isOpen){
      GA4.event(GA4.CLICK, {
        page: GA4.PAGE.TOP_MENU,
        subPage: "-",
        element: "avatar-btn",
        action: "open-user-menu"
      })
      RewardsApi.read.getRewards({ eid: licenseId }, (resp) => {
        resp.eid = licenseId;
        EnrollStorage.setRewards(resp);
        this.__rewards.ludus = resp.lus_g;
        this.__rewards.ludis = resp.lns_g;
        this.__rewards.likes = resp.lks_g;
        this.__rewards.achievements = resp.ac_g;
        this.#__render();
      })
    }
  }

  _openEnrollmentMenu(){
    let inputSelect = this.parentElement;
    inputSelect.classList.toggle('active');
    GA4.event(GA4.CLICK, {
      page: GA4.PAGE.TOP_MENU,
      subPage: "-",
      element: "products-select",
      action: "open-select-options"
    })
  }

 

  menuClick(option){
    let avatar = this.root.querySelector('#avatarMenuId');
    if(option == 'logout'){
      StorageService.clearAll();
      GA4.event(GA4.CLICK, {
        page: GA4.PAGE.TOP_MENU,
        subPage: "-",
        element: "avatar-btn",
        action: "logout"
      })
      window.location.href = "/";
      return;
    }

    if(option == 'subscriptions'){
      window.open(E_COMMERCE + "suscripciones", "_blank");
      return;
    }

    if (option == 'support') {
      const destinatario = 'hola@meludus.com';
      const asunto = 'Asunto del correo';
      const mailtoLink = `mailto:${destinatario}?subject=${encodeURIComponent(asunto)}`;
      window.open(mailtoLink);
      return;
    }
    avatar.classList.remove('open');
  }

  _clickInputSelect(productId){
    if(this.__currentEnrollment == productId) return 0;
    const licenseList = EnrollStorage.getEnrollList();
    let product = licenseList.find(product => product.productId == productId);
    let input = this.root.querySelector('#inputEnrollmentSelectId');
    input.classList.remove('active');

    GA4.event(GA4.CLICK, {
      page: GA4.PAGE.TOP_MENU,
      subPage: "-",
      element: "products-select",
      action: "select-law",
      product: `${product.name} - ${product.productId}`
    })

    g_loading();
    let service = new CloudService();
    service.setData(ATTR.LAST_PRODUCT, product.productId, () => {
      EnrollStorage.selectLicense(product.productId);
      ClientDB.resetEid();
      if(location.pathname.includes('/deconstructor'))
        removeKeyFromState('node');
      TreeStorage.clear();
      window.location.reload();
    })
  }

  refreshAvatar = () => {
    this.#__render();
  }

  getImg(img){
    let url = "https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/icons/b/";
    return url + img;
  }

  showBoxHelp(){
    this.root.querySelector('#helpBox').classList.toggle('hidden');
    this.onBoardingBoxHelp ? this.root.querySelector('#ayuda').classList.add('markLawList') : this.root.querySelector('#ayuda').classList.remove('markLawList');
  }

  startTutorial(){
    const tdashboard = document.querySelector('t-dashboard');
    const ttest= document.querySelector('t-test');
    if (tdashboard) {
      tdashboard.initOnBoarding()
    }else if (ttest) {
      ttest.initOnBoarding()
    }
  }

  startVideoTutorials(){
    document.dispatchEvent(new CustomEvent('startTutorialVideos', {
      detail: { start: true }
    }))
  }

  __getMenuOption(flag, svg, label){
    const showBackground = this.showBackground ?? true;
    const clsBackground = showBackground ? 'bg--white' : '';

    if(flag) return html`
      <div id="${label}" class="nav-item flex flex-col items-center cursor-pointer" @click="${this.showBoxHelp.bind(this)}">
        <img class="w-12 h-12" src="${this.getImg(svg)}" />
        <span class="text-base font-normal">${label}</span>
        <div id="helpBox" class="helpBox ${clsBackground} hidden">
          <p class="text-base font-normal hover:font-bold" @click="${this.startTutorial.bind(this)}">Guía ludubot</p>
          <p class="text-base font-normal hover:font-bold" @click="${this.startVideoTutorials.bind(this)}">Videotutoriales</p>
        </div>
      </div>`
    return "";
  }

  addEnrollment(enrollments, currentEnrollment){
    this.__enrollments = enrollments;
    this.__currentEnrollment = currentEnrollment;
    this.#__render();
  }

  __getRewardView(icon, data){
    return html`
      <div class="flex items-end justify-center gap-x-1">
        <i class="icon-1 ${icon} ml_primary m-0 p-0 w-auto h-auto
          flex items-center justify-center"></i>
        <span class="p_14 semibold min-w-[45px]">${data}</span>
      </div>`
  }

  __getSubMenuOption(flag, type, icon, label){
    if(flag) return html`
      <div 
        @click="${this.menuClick.bind(this, type)}"
          class="nav-item flex flex-col items-center">
        <i class="ml-icon ml-icon-${icon} ml_primary"></i>
        <span class="text-lg">${label}</span>
      </div>`
    return ""
  }

  __getEnrollmentData(){
    if(!Boolean(this.__enrollments)) return "";
    if(this.__enrollments.length < 2) return { currentEnroll: "", enrollmentList: "" };
    
    let enrollmentList = [];
    let currentEnroll = ""
    this.__enrollments.forEach((product) => {
      let optionCls = "regular";
      if(product.prid == this.__currentEnrollment){
        currentEnroll = product.prnam
        optionCls = "bold";
      }
      enrollmentList.push(html`
        <p 
          @click=${() => this._clickInputSelect(product.prid)} 
          class="p_12 ${optionCls} cursor-pointer">
          ${product.prnam}
        </p>
      `)
    });

    return {
      currentEnroll,
      enrollmentListView: html`
        <div class="ml-section-option-wrap mb-2">
          <div class="ml-menu-section-title mb-2 hidden">
            <span class="p_14 bold ml_primary">Mis Oposiciones y Leyes</span>
            <i class=" icon-2 ml-icon-lapiz ml_primary "> </i>
          </div>
          <div class="ml-options-wrap mt-2">
            ${ enrollmentList }
          </div>
        </div>`,
    }
  }

  __getMainTitleView(showSelect){
    if(!showSelect) return "";
    if(!Boolean(this.__enrollments)) return "";
    const { titlePage, withBackBtn } = this;
    const paddingLeft = `padding-left: ${withBackBtn ? '160' : '40' }px`;
    const paddingLeftMobile = `padding: 0px 20px 10px 20px; width: 100%`;
    const isMobile = window.screen.width <= 800;
    let showOnboarding = this.onBoardingList ? 'markLawList' : '';


    if(this.__enrollments.length == 1){
      let product = this.__enrollments[0];
      return html`
        <div id='selectLaw' class='${showOnboarding}' style="${isMobile ? paddingLeftMobile : paddingLeft}">
          <h2 class="title_4 bold main-title" style="margin: 0px 0px 4px 0px">
            ${ titlePage }
          </h2>
          <p class="p_12 bold"> ${product.prnam} </p>
        </div>`
    }else{
      let enrollmentData = this.__getEnrollmentData(); 
      return html`
        <div id='selectLaw' class='${showOnboarding}' style="${isMobile ? paddingLeftMobile : paddingLeft}">
          <h2 class="title_4 bold main-title" style="margin: 0px 0px 4px 0px">
            ${ titlePage }
          </h2>
          <div class="ml-select-enrollment">
            <div id="inputEnrollmentSelectId" class="ml-select-wrap">
              <div @click="${this._openEnrollmentMenu}" class="ml-select-input cursor-pointer">
                <span class="p_12 bold"> ${ enrollmentData.currentEnroll } </span>
                <i class=" icon-1 ml-icon-flecha_desplegar ml_primary "> </i>
              </div>
              <div class="ml-select-options-wrap">
                ${ enrollmentData.enrollmentListView }
              </div>
            </div>
          </div>
        </div>`
    }
  }

  __getQuStatBar(){
    const { __stats: _stats } = this;
    let stats = _stats ?? { ans_ok: 0, never_seen: 0, tot_q: 0 };
    let okPer = stats.tot_q && Math.round((stats.ans_ok * 100) / stats.tot_q);
    return html`
      <div class="range-container flex items-end gap-x-3 max-w-4xl mt-6">
        <span class="text-3xl -mb-2 font-extrabold text-dark3 w-[80px] text-right">
          ${okPer}%
        </span>
        <div class="range-block flex flex-col w-full">
          <div class="flex items-center gap-x-2">
            <i class="icon-3 m-0 p-0 ml-icon-pregunta w-auto h-auto
          text-4xl ml_success flex items-center justify-center"></i>
            <span class="text-lg">
              ${stats.ans_ok} preguntas acertadas
            </span>
            <span class="text-lg ml-auto">
              ${stats.tot_q} totales
            </span>
          </div>
          <div class="range rounded-full bg-success4 w-full relative h-2">
            <div
              class="rounded-full bg-success absolute inset-0"
              style="width: ${okPer}%">
            </div>
          </div>
        </div>
      </div>
    `
  }

  __getDjStatBar(){
    const customTree = TreeStorage.getCustomTree();
    let djStat = { art: 0, dj_pop: 0 };
    if (customTree) djStat = customTree.djStat;
    const completed = Math.floor(djStat.dj_pop / 100)
    let percent = djStat.art && Math.round((completed * 100) / djStat.art);
    return html`
      <div class="range-container flex items-end gap-x-3 max-w-4xl mt-6">
        <span class="text-3xl -mb-2 font-extrabold text-dark3 w-[80px] text-right">
          ${percent}%
        </span>
        <div class="range-block flex flex-col w-full">
          <div class="flex items-center gap-x-2">
            <i class="icon-3 m-0 p-0 ml-icon-deconstructor w-auto h-auto
          text-4xl ml_secondary flex items-center justify-center"></i>
            <span class="text-base ml-1">
              ${Math.floor(djStat.dj_pop / 100)} artículos completados
            </span>
            <span class="text-base ml-auto">
              ${djStat.art} totales
            </span>
          </div>
          <div class="range rounded-full bg-secondary4 w-full relative h-2">
            <div
              class="rounded-full bg-secondary absolute inset-0"
              style="width: ${percent}%">
            </div>
          </div>
        </div>
      </div>
    `
  }

  /** ______________________ RENDER ______________________________ */
  #template() {
    
    const user = AuthStorage.getUser();
    const {
      showHelp,
      showNotifications,
      showSelect,
      __avatar: defaultAvatar,
      __rewards: rewards,
    } = this;
    const onBoarding = this.onBoardingList || this.onBoarding || this.onBoardingBoxHelp ? 'onBoarding_position' : '';
    const avatar = `${URL_STATIC}avatar/${user?.pid}-thumbnail.png?${new Date().getTime()}`;
    return /* template */html`
      <div class="flex items-center h-32 md:pr-16 ml-menu ${onBoarding} box-border">
        ${this.__getMainTitleView(showSelect)}
        <div class="right-side ml-auto flex gap-x-7 items-center">
          <slot></slot>
          ${this.__getMenuOption(showHelp, 'pregunta_1.svg', 'ayuda')}
          ${this.__getMenuOption(showNotifications, 'campana.svg', 'notificaciones')}

          <div id="avatarMenuId" class="avatar">
            <div @click="${this.openUser}" class="avatar-menu-btn">
              <div class="flex items-center ml_avatar_container">
                <div class="rounded-full ml_bk-primary w-[25px] h-[25px]">
                  <img
                    src="${avatar}"
                    onerror="this.src.includes('avatar-thumbnail.png') ? null :
                      this.src='${defaultAvatar + '?' + new Date().getTime()}'"
                    class="ml-avatar-top-menu"
                    alt="avatar">
                </div>
                <i class=" icon-1 ml-icon-flecha_desplegar ml_primary nick-arrow "> </i>
              </div>
              <span class="text-base font-normal"> ${user?.nick} </span>
            </div>
            
            <div id='cardMenu' class="submenu pl-4 cursor-default ">
              <div class="flex items-center justify-between gap-x-2 mt-5">
                ${this.__getRewardView('ml-icon-ludus', rewards?.ludus)}
                ${this.__getRewardView('ml-icon-ludin', rewards?.ludis)}
                ${this.__getRewardView('ml-icon-ranking_medalla', rewards?.achievements)}
                ${this.__getRewardView('ml-icon-like', rewards?.likes)}
              </div>

              <div class="mt-6">
                ${this.__getQuStatBar()}
                ${this.__getDjStatBar()}
              </div>
              <div class="bottom-side mt-16">
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-5">
                    ${this.__getSubMenuOption(false, 'account', 'usuario', 'mi cuenta')}
                    ${this.__getSubMenuOption(true, 'subscriptions', 'calendario', 'suscripciones')}
                    ${this.__getSubMenuOption(true, 'support', 'email', 'soporte')}
                  </div>
                  ${this.__getSubMenuOption(true, 'logout', 'salir', 'salir')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }
  /** ____________________________________________________________ */







  /**
   * ========================= PRIVATE FUNCTIONS ===========================
   * (Don't modify the functions below. Copy-paste to other web components)
   * ==================================================================== */

  /** Init web component config to be executed in constructor */
  #__init({ shadowDOM = true } = {}) {
    this.__isShadowDOM = shadowDOM;
    if (this.__isShadowDOM) this.root = this.attachShadow({ mode: 'open'});
    this.#__render();
    this.#__attachStyle();
    this.__initialized = false;
  }

  /** Inject processed SCSS Style */
  #__attachStyle() {
    const style = document.createElement('style');
    style.textContent = styles
      .replace(/(\/)([\w?\d?])/g, '\\/$2')              // Fix for tailwind 'w-1/2' classes
      .replace(/(\[)(\w?\d?(?:rem|px|%)?)/g, '\\[$2\\') // Fix for tailwind custom 'w-[50px]' classes
      .replace(/(\.\w+)(\:)(\w+)/g, '$1\\:$3');
    const appendStyleTo = this.__isShadowDOM
      ? this.root
      : document.head || document.getElementsByTagName('head')[0];

    appendStyleTo.prepend(style);
  }


  /** Internal function to update the UI based (re-render and first render) */
  #__render() {
    const attachTo = this.__isShadowDOM ? this.root : this;
    render(this.#template(), attachTo);
  }
  /** ============================================================= */
}

window.customElements.define('ml-menu', TopMenu);
