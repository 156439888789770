import { toNode } from 'utils/html_creator';
import view from './layout.html';

/*
 * @param: remove as boolean
 * */
const loading = (flag=true, full) => {
  let element = document.getElementById("ml_loading21");
  if (element && flag) return;
  if(!flag){
    if(element && element.firstElementChild){
      element.firstElementChild.removeFig(1);
      setTimeout(() => {
        element.classList.add('remove_loading');
        setTimeout(() => {
          if(element)
          element.remove();
        }, 405);
      }, 2000);
    }
  }else{
    let $view = toNode(view);
    $view.classList.toggle('overall', full);
    document.querySelector('body').appendChild($view);
    $view.firstElementChild.animate({ duration: 0 });
  }
}

export default loading;

