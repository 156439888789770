import { html } from 'lit-html';
import styles from './_.styles.scss';
import { navigateTo } from 'routing';
import { URL_STATIC } from 'utils/_.env.js';
import { MDD } from 'utils/_.env';
import cn from 'utils/class-names';
import { RewardsApi } from 'api_connection';
import WebComponent from './../../WebComponent';
import { getState } from 'storage';
import { nav } from 'utils/const';
import { E_COMMERCE } from 'utils/_.env';
import { StorageService } from 'storage/Storage.storage';
import { AuthStorage } from 'storage/Auth.storage';
import { EnrollStorage } from 'storage/Enroll.storage';

class MobileMenu extends WebComponent {
  static get observedAttributes() {
    return [
      'is-open',
      'back-link-label',
      'back-link-url',
      'nav-type'
    ];
  }

  get isOpen() {return this.__isOpen}
  set isOpen(value) {
    this.__isOpen = value === 'true' || value === true;
    this.render();
  }

  get backLinkLabel() {return this.__backLinkLabel}
  set backLinkLabel(value) {
    this.__backLinkLabel = value;
    this.render();
  }

  get backLinkUrl() {return this.__backLinkUrl}
  set backLinkUrl(value) {
    this.__backLinkUrl = value;
    this.render();
  }

  get navType() { return this.__navType }
  set navType(value) {
    this.__navType = value;
    this.render();
  }

  constructor() {
    super(styles, false, 'ml-menu-mobile');
    this.__body = document.body;
  }

  initialize() {
    this.__avatar = `${URL_STATIC}avatar/avatar-thumbnail.png`;
    this.__rewards = { achievements: 0, likes: 0, ludus: 0, ludis: 0 };
    window.addEventListener("scroll", this.onScrollMovement.bind(this), true);
  }

  disconnectedCallback(){
    window.removeEventListener("scroll", this.onScrollMovement, true);
  }

  onScrollMovement = (e) => {
    const paths = ["/mis-tests", "/panel-de-usuario"];
    const scrollPosition = window.scrollY;
    this.classList.toggle("scrolled", scrollPosition > 50);
    this.classList.toggle("shadow", !paths.includes(location.pathname) && scrollPosition > 50);
  }

  toggleMenuOpen = () => {
    const rewards = EnrollStorage.getRewards();
    const { licenseId } = EnrollStorage.getEnrollSelected();

    let body = this;
    this.isOpen = !this.isOpen;
    body.querySelector('.menu-btn').classList.toggle('open');
    body.querySelector('.back_link')?.classList.toggle('hidden');
    this.__body.classList.toggle('block-scroll', this.isOpen);
    this.__rewards = rewards.general;

    if(this.isOpen){
      let { path } = getState();
      this.activeLink(path.replace("/", ""));
      RewardsApi.read.getRewards({ eid: licenseId }, (resp) => {
        resp.eid = licenseId;
        EnrollStorage.setRewards(resp);
        this.__rewards.ludus = resp.lus_g;
        this.__rewards.ludis = resp.lns_g;
        this.__rewards.likes = resp.lks_g;
        this.__rewards.achievements = resp.ac_g;
        this.render();
      })
    }
  }

  navigatePage(path) {
    this.toggleMenuOpen();
    if(path == 'logout'){
      StorageService.clearAll();
      window.location.href = "/";
      return;
    }

    if (path == 'subscriptions') {
      window.open(E_COMMERCE + "suscripciones", "_blank");
      return;
    }

    if (path == 'support') {
      const destinatario = 'hola@meludus.com';
      const asunto = 'Asunto del correo';
      const mailtoLink = `mailto:${destinatario}?subject=${encodeURIComponent(asunto)}`;
      window.open(mailtoLink);
      return;
    }
    
    this.activeLink(path);
    navigateTo(`/${path}`);
  }

  activeLink(path){
    let body = this;
    let cls = `.li-${path}`;
    body.querySelector('.option-wrap.active')?.classList.remove('active');
    body.querySelector(cls)?.classList?.add('active');
  }

  getImg(img){
    let url = "https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/icons/b/"
    return url + img;
  }
  
  getNavItem(link, text, icon){
    return html`
      <div @click=${() => this.navigatePage(link)} class="option-wrap li-${link}">
        <div class="option">
          <i class="icon-1 ${icon}"></i>
          <span>${text}</span>
        </div>
      </div>
    `;
  }

  getNavItemV2(link, text, icon) {
    return html`
      <div @click=${() => this.navigatePage(link)} class="option-btn">
        <i class="icon-2 ${icon} ml_primary"></i>
        <span class="semibold">${text}</span>
      </div>
    `;
  }

  reload(){
    this.render();
  }

  showBoxHelp(){
    console.log("showBoxHelp");
    document.querySelector('#helpBox').classList.toggle('hidden');
    this.onBoardingBoxHelp ? document.querySelector('#ayuda').classList.add('markLawList') : document.querySelector('#ayuda').classList.remove('markLawList');
  }
  
  startTutorial(){
    // document.dispatchEvent(new CustomEvent('startTutorial', {
    //   detail: { start: true }
    // }))
  }

  startVideoTutorials(){
    document.dispatchEvent(new CustomEvent('startTutorialVideos', {
      detail: { start: true }
    }))
  }

  template() {
    const {
      isOpen,
      backLinkLabel,
      backLinkUrl,
      __avatar: defaultAvatar,
      __rewards: rewards,
      navType,
    } = this;
    const { user, ua } = AuthStorage.getAuth();
    const avatar = `${URL_STATIC}avatar/${user.pid}-thumbnail.png?${new Date().getTime()}`;

    return /* template */html`
      <div @click=${this.showBoxHelp.bind(this)} class="menu-btn-help hidden">
        <img class="w-16 h-16" src="${this.getImg('pregunta_1.svg')}" />
        <div id="helpBox" class="helpBox hidden">
          <p class="text-base font-normal hover:font-bold" @click="${this.startTutorial.bind(this)}">Guía ludubot</p>
          <p class="text-base font-normal hover:font-bold" @click="${this.startVideoTutorials.bind(this)}">Videotutoriales</p>
        </div>
      </div>
      <div @click=${this.toggleMenuOpen} class="menu-btn">
        <span class="menu-btn__burger"></span>
      </div>
      <div class="flex flex-col items-start w-full max-w-full pl-8 pt-7 pr-2 fixed">
        ${navType == "false" || navType == nav.MENU
          ? html`<img class="img-logo" src="${ua.ua_logo_url}" />` 
          : html``
        }
        
        ${navType == nav.BACK
          ? html`
            <a
              class="ml_dark no-underline p_14 regular flex items-center back_link"
              href=${backLinkUrl}>
              <i class=" 
                icon-2 ml-icon-flecha ml_primary 
                flex justify-center items-center -mt-1"></i>
              ${backLinkLabel}
            </a>`
          : html``
        }
      </div>

      <div class="menu-popup ${ isOpen ? 'show' : ''}">
        <div class="square-top"></div>
        <div class="square-bottom"></div>

        <div class="menu-popup__logo">
          <img class="w-[180px]" src="${ua.ua_logo_url}" alt="logo"/>
        </div>

        <div class="avatar-wrap">
          <img
            class="w-[100px] h-[100px] rounded-full ml_bk-primary"
            src="${avatar}"
            onerror="this.src='${defaultAvatar + '?' + new Date().getTime()}'"
            alt="avatar">
          <p>${user.nick}</p>
        </div>

        <div class="user-info">
          <div class="flex gap-x-4 items-center">
            <i class="icon-1 ml-icon-ludus ml_primary"></i>
            <span class="p_14 semibold">${rewards?.ludus}</span>
          </div>

          <div class="flex gap-x-4 items-center">
            <i class="icon-1 ml-icon-ludin ml_primary"></i>
            <span class="p_14 semibold">${rewards?.ludis}</span>
          </div>

          <div class="flex gap-x-4 items-center">
            <i class="icon-1 ml-icon-ranking_medalla ml_primary"></i>
            <span class="p_14 semibold">${rewards?.achievements}</span>
          </div>

          <div class="flex gap-x-4 items-center">
            <i class="icon-1 ml-icon-like ml_primary"></i>
            <span class="p_14 semibold">${rewards?.likes}</span>
          </div>
        </div>

        <div class="menu-options">
          ${ this.getNavItem('panel-de-usuario', 'Estadísticas', 'ml-icon-analiticas-1') }
          ${ this.getNavItem('mis-tests', 'Tests', 'ml-icon-test')}
          ${ this.getNavItem('rankings', 'Ranking', 'ml-icon-menu_ranking') }
          ${ this.getNavItem('logros', 'Logros', 'ml-icon-ranking_medalla')}
        </div>
        <div class="user-options">
          ${ this.getNavItemV2('subscriptions', 'suscripciones', 'ml-icon-calendario')}
          ${ this.getNavItemV2('support', 'soporte', 'ml-icon-email')}
          ${ this.getNavItemV2('logout', 'salir', 'ml-icon-salir') }
        </div>
      </div>
    `;
  }
}

window.customElements.define('ml-menu-mobile', MobileMenu);

